import { useState, useEffect } from "react";
import Api from "./api";
import { getTextWithoutMentionsAndTags } from "./helpers";
import { ProjectInfo } from "./useObserveProject";
import { ChatMessage } from "./models";
import { useTheme } from "./useTheme";

const theme = useTheme();

export interface TagInfo {
  id: string;
  tag: string;
  description: string;
}

export interface TransformInfo {
  id: string;
  tag: string;
  content_match: string;
  set_parent: string;
  prompt: string;
  new_tag: string;
}

export type ChatFunction =
  | "post_codicent_message"
  | "get_messages_list"
  | "get_data_items"
  | "search_web"
  | "get_context"
  | "get_text_from_url"
  | "get_messages_count"
  | "get_html_from_url";

const useObserveCodicentSettings = ({ username, project }: { username: string; project: ProjectInfo }) => {
  const { getPropertyValue, addOrUpdateProperty } = project;
  const { nickname } = project;
  const isUser = username === project.nickname;

  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(
    localStorage.getItem("audio_device_id") || undefined
  );

  useEffect(() => {
    enumerateDevices();
  }, []);

  const enumerateDevices = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter((d) => d.kind === "audioinput");
      if (audioDevices.length === 1 && !selectedDeviceId) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      } else {
        setAudioDevices(audioDevices);
        if (audioDevices.length === 0) {
          setSelectedDeviceId(undefined);
        } else {
          setSelectedDeviceId(selectedDeviceId || audioDevices[0].deviceId);
        }
      }
    } catch (e) {
      console.log("Error enumerating devices", e);
    }
  };

  useEffect(() => {
    if (selectedDeviceId) {
      localStorage.setItem("audio_device_id", selectedDeviceId);
    }
  }, [selectedDeviceId]);

  const [tags, setTags] = useState<string[]>([]);
  useEffect(() => {
    Api.getTags(nickname).then(setTags).catch(console.log);
  }, [nickname]);

  const [coAdmins, setCoAdmins] = useState<string[]>(
    (getPropertyValue("CO_ADMINS") || "")
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0)
  );
  useEffect(() => {
    addOrUpdateProperty("CO_ADMINS", coAdmins.join(","));
  }, [coAdmins]);

  const [chatFunctions, setChatFunctions] = useState<ChatFunction[]>(
    (
      getPropertyValue("CODICENT_FUNCTIONS_FILTER") ||
      "post_codicent_message,get_messages_list,get_data_items,search_web,get_text_from_url"
    )
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0) as ChatFunction[]
  );
  useEffect(() => {
    addOrUpdateProperty("CODICENT_FUNCTIONS_FILTER", chatFunctions.join(","));
  }, [chatFunctions]);

  const [homeUrl, setHomeUrl] = useState(getPropertyValue("CODICENT_HOME_URL"));
  useEffect(() => {
    addOrUpdateProperty("CODICENT_HOME_URL", homeUrl || "");
  }, [homeUrl]);

  const [indexTags, setIndexTags] = useState(
    (getPropertyValue("CODICENT_INDEX_TAGS") || "")
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0)
  );
  useEffect(() => {
    addOrUpdateProperty("CODICENT_INDEX_TAGS", indexTags.join(","));
  }, [indexTags]);

  const [githubBranch, setGithubBranch] = useState(getPropertyValue("GITHUB_BRANCH"));
  useEffect(() => {
    addOrUpdateProperty("GITHUB_BRANCH", githubBranch || "");
  }, [githubBranch]);

  const [githubRepo, setGithubRepo] = useState(getPropertyValue("GITHUB_REPO"));
  useEffect(() => {
    addOrUpdateProperty("GITHUB_REPO", githubRepo || "");
  }, [githubRepo]);

  const [githubToken, setGithubToken] = useState(getPropertyValue("GITHUB_TOKEN"));
  useEffect(() => {
    addOrUpdateProperty("GITHUB_TOKEN", githubToken || "");
  }, [githubToken]);

  const [sharepointClientId, setSharepointClientId] = useState(getPropertyValue("SHAREPOINT_CLIENT_ID"));
  useEffect(() => {
    addOrUpdateProperty("SHAREPOINT_CLIENT_ID", sharepointClientId || "");
  }, [sharepointClientId]);

  const [sharepointClientSecret, setSharepointClientSecret] = useState(getPropertyValue("SHAREPOINT_CLIENT_SECRET"));
  useEffect(() => {
    addOrUpdateProperty("SHAREPOINT_CLIENT_SECRET", sharepointClientSecret || "");
  }, [sharepointClientSecret]);

  const [sharepointTenantId, setSharepointTenantId] = useState(getPropertyValue("SHAREPOINT_TENANT_ID"));
  useEffect(() => {
    addOrUpdateProperty("SHAREPOINT_TENANT_ID", sharepointTenantId || "");
  }, [sharepointTenantId]);

  const [useFunctions, setUseFunctions] = useState(getPropertyValue("USE_FUNCTIONS") === "1");
  useEffect(() => {
    addOrUpdateProperty("USE_FUNCTIONS", useFunctions ? "1" : "0");
  }, [useFunctions]);

  const [featureAutoTag, setFeatureAutoTag] = useState(getPropertyValue("FEATURE_AUTO_TAG") === "1");
  useEffect(() => {
    addOrUpdateProperty("FEATURE_AUTO_TAG", featureAutoTag ? "1" : "0");
  }, [featureAutoTag]);

  const [featureChatButton, setFeatureChatButton] = useState(getPropertyValue("FEATURE_CHAT_BUTTON") || "");
  useEffect(() => {
    addOrUpdateProperty("FEATURE_CHAT_BUTTON", featureChatButton);
  }, [featureChatButton]);

  const [featureSearchButton, setFeatureSearchButton] = useState(getPropertyValue("FEATURE_SEARCH_BUTTON") === "1");
  useEffect(() => {
    addOrUpdateProperty("FEATURE_SEARCH_BUTTON", featureSearchButton ? "1" : "0");
  }, [featureSearchButton]);

  const [hideTags, setHideTags] = useState(getPropertyValue("HIDE_TAGS") === "1");
  useEffect(() => {
    addOrUpdateProperty("HIDE_TAGS", hideTags ? "1" : "0");
  }, [hideTags]);

  const [jiraProject, setJiraProject] = useState(getPropertyValue("JIRA_PROJECT"));
  useEffect(() => {
    addOrUpdateProperty("JIRA_PROJECT", jiraProject || "");
  }, [jiraProject]);

  const [jiraToken, setJiraToken] = useState(getPropertyValue("JIRA_TOKEN"));
  useEffect(() => {
    addOrUpdateProperty("JIRA_TOKEN", jiraToken || "");
  }, [jiraToken]);

  const [jiraUrl, setJiraUrl] = useState(getPropertyValue("JIRA_URL"));
  useEffect(() => {
    addOrUpdateProperty("JIRA_URL", jiraUrl || "");
  }, [jiraUrl]);

  const [jiraUsername, setJiraUsername] = useState(getPropertyValue("JIRA_USERNAME"));
  useEffect(() => {
    addOrUpdateProperty("JIRA_USERNAME", jiraUsername || "");
  }, [jiraUsername]);

  const [slackSigningSecret, setSlackSigningSecret] = useState(getPropertyValue("SLACK_SIGNING_SECRET"));
  useEffect(() => {
    addOrUpdateProperty("SLACK_SIGNING_SECRET", slackSigningSecret || "");
  }, [slackSigningSecret]);

  const [teamsWebhookUrl, setTeamsWebhookUrl] = useState(getPropertyValue("TEAMS_WEBHOOK_URL"));
  useEffect(() => {
    addOrUpdateProperty("TEAMS_WEBHOOK_URL", teamsWebhookUrl || "");
  }, [teamsWebhookUrl]);

  const [discordWebhook, setDiscordWebhook] = useState(getPropertyValue("DISCORD_WEBHOOK"));
  useEffect(() => {
    addOrUpdateProperty("DISCORD_WEBHOOK", discordWebhook || "");
  }, [discordWebhook]);

  const [userEmailCodicentName, setUserEmailCodicentName] = useState(getPropertyValue("DEFAULT_EMAIL_MENTION"));
  useEffect(() => {
    addOrUpdateProperty("DEFAULT_EMAIL_MENTION", userEmailCodicentName || "");
  }, [userEmailCodicentName]);

  const [longPressUrl, setLongPressUrl] = useState(getPropertyValue("CODICENT_LONGPRESS_URL"));
  useEffect(() => {
    addOrUpdateProperty("CODICENT_LONGPRESS_URL", longPressUrl || "");
  }, [longPressUrl]);

  const [isPublic, setIsPublic] = useState(getPropertyValue("CODICENT_IS_PUBLIC") === "1");
  useEffect(() => {
    addOrUpdateProperty("CODICENT_IS_PUBLIC", isPublic ? "1" : "0");
  }, [isPublic]);

  const [defaultMention, setDefaultMention] = useState(getPropertyValue("DEFAULT_MENTION"));
  useEffect(() => {
    addOrUpdateProperty("DEFAULT_MENTION", defaultMention || "");
  }, [defaultMention]);

  // TODO: CODICENT_THEME, DEFAULT_EMAIL_MENTION, CODICENT_TITLE, OPENAI_API_KEY, CHATS_ALLOWED_LIST

  const [tagInfos, setTagInfos] = useState<TagInfo[]>([]);
  useEffect(() => {
    if (!isUser) {
      Api.findDataMessagesWithId(nickname, ["taginfo"])
        .then((tagInfoData) => {
          const tagInfos = tagInfoData.map((tagInfo) => ({
            id: tagInfo._id,
            tag: tagInfo.Tag,
            description: tagInfo.Description,
          }));
          setTagInfos(tagInfos);
        })
        .catch(console.log);
    }
  }, [nickname]);
  const deleteTagInfo = (id: string) => {
    Api.hideMessage(id).catch(console.log);
    setTagInfos(tagInfos.filter((p) => p.id !== id));
  };
  const addTagInfo = async (tag: string, description: string) => {
    const item: ChatMessage = {
      id: undefined,
      parentId: undefined,
      content: `@${nickname} #taginfo #data\n${JSON.stringify({
        Tag: tag,
        Description: description,
      })}`,
      type: "info",
      createdAt: new Date(),
      nickname: nickname,
      isNew: false,
    };

    try {
      const newId = await Api.addChatMessage(item);
      setTagInfos([...tagInfos, { id: newId, tag, description }]);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTagInfo = async (id: string, tag: string, description: string) => {
    const item: ChatMessage = {
      id: undefined,
      parentId: id,
      content: `@${nickname} #taginfo #data\n${JSON.stringify({
        Tag: tag,
        Description: description,
      })}`,
      type: "info",
      createdAt: new Date(),
      nickname: nickname,
      isNew: false,
    };

    try {
      const newId = await Api.addChatMessage(item);
      setTagInfos(tagInfos.map((p) => (p.id === id ? { tag, description, id: newId } : p)));
    } catch (error) {
      console.log(error);
    }
  };

  const [transforms, setTransforms] = useState<TransformInfo[]>([]);
  useEffect(() => {
    if (!isUser) {
      Api.findDataMessagesWithId(nickname, ["transform"])
        .then((transformData) => {
          const transforms = transformData.map((t) => ({
            id: t._id,
            tag: t.tag,
            content_match: t.content_match,
            set_parent: t.set_parent,
            prompt: t.prompt,
            new_tag: t.new_tag,
          }));
          setTransforms(transforms);
        })
        .catch(console.log);
    }
  }, [nickname]);
  const deleteTransform = (id: string) => {
    Api.hideMessage(id).catch(console.log);
    setTransforms(transforms.filter((p) => p.id !== id));
  };
  const addTransform = async (t: TransformInfo) => {
    const transform = {
      tag: t.tag,
      content_match: t.content_match,
      set_parent: t.set_parent,
      prompt: t.prompt,
      new_tag: t.new_tag,
    };
    const item: ChatMessage = {
      id: undefined,
      parentId: undefined,
      content: `@${nickname} #taginfo #data\n${JSON.stringify(transform)}`,
      type: "info",
      createdAt: new Date(),
      nickname: nickname,
      isNew: false,
    };

    try {
      const newId = await Api.addChatMessage(item);
      setTransforms([...transforms, { id: newId, ...transform }]);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTransform = async (t: TransformInfo) => {
    const transform = {
      tag: t.tag,
      content_match: t.content_match,
      set_parent: t.set_parent,
      prompt: t.prompt,
      new_tag: t.new_tag,
    };

    const item: ChatMessage = {
      id: undefined,
      parentId: t.id,
      content: `@${nickname} #transform #data\n${JSON.stringify(transform)}`,
      type: "info",
      createdAt: new Date(),
      nickname: nickname,
      isNew: false,
    };

    try {
      const newId = await Api.addChatMessage(item);
      setTransforms(transforms.map((p) => (p.id === t.id ? { ...t, id: newId } : p)));
    } catch (error) {
      console.log(error);
    }
  };
  return {
    coAdmins,
    setCoAdmins,
    chatFunctions,
    setChatFunctions,
    homeUrl,
    setHomeUrl,
    indexTags,
    setIndexTags,
    githubBranch,
    setGithubBranch,
    githubRepo,
    setGithubRepo,
    githubToken,
    setGithubToken,
    sharepointClientId,
    setSharepointClientId,
    sharepointClientSecret,
    setSharepointClientSecret,
    sharepointTenantId,
    setSharepointTenantId,
    useFunctions,
    setUseFunctions,
    featureAutoTag,
    setFeatureAutoTag,
    featureChatButton,
    setFeatureChatButton,
    featureSearchButton,
    setFeatureSearchButton,
    hideTags,
    setHideTags,
    jiraProject,
    setJiraProject,
    jiraToken,
    setJiraToken,
    jiraUrl,
    setJiraUrl,
    jiraUsername,
    setJiraUsername,
    slackSigningSecret,
    setSlackSigningSecret,
    teamsWebhookUrl,
    setTeamsWebhookUrl,
    discordWebhook,
    setDiscordWebhook,
    tags,
    userEmailCodicentName,
    setUserEmailCodicentName,
    isPublic,
    setIsPublic,
    defaultMention,
    setDefaultMention,
    isUser,
    tagInfos,
    setTagInfos,
    addTagInfo,
    updateTagInfo,
    deleteTagInfo,
    transforms,
    setTransforms,
    addTransform,
    updateTransform,
    deleteTransform,
    audioDevices,
    selectedDeviceId,
    setSelectedDeviceId,
  };
};

export default useObserveCodicentSettings;
